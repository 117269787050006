import { lazy, Suspense } from 'react';
import { Navigate, useRoutes, Outlet } from 'react-router-dom';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import CompactLayout from 'src/layouts/compact';
import { SplashScreen } from 'src/components/loading-screen';
//
import { authRoutes } from './auth';
import { demoRoutes } from './demo';
import { appRoutes } from './app';

// ----------------------------------------------------------------------
const Page404 = lazy(() => import('src/pages/404'));

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Auth routes
    ...authRoutes,
    ...appRoutes,

    {
      element: (
        <CompactLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </CompactLayout>
      ),
      children: [{ path: '404', element: <Page404 /> }],
    },
    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },

    // Demo routes
    ...demoRoutes,
  ]);
}
