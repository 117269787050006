import MainLayout from 'src/layouts/main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { mainRoutes, HomePage } from './main';
import { componentsRoutes } from './components';

export const demoRoutes = [
  {
    path: 'demo',
    element: (
      <MainLayout>
        <HomePage />
      </MainLayout>
    ),
    children: [...authRoutes, ...dashboardRoutes, ...mainRoutes, ...componentsRoutes],
  },
];
