import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  IGetReportListParams,
  IReportItem,
  IReportChartTypeItem,
  IReportMetricItem,
  IReportProidItem,
  IReportCalculationTypeItem,
} from 'src/types/report';
import {
  fetchReportList,
  getMetric,
  getPeriod,
  getCalculationType,
  getChartType,
} from 'src/api/report';

export interface FetchReportListPayload {
  // 可以在这里定义请求参数
  payload: Partial<IGetReportListParams>;
}

export const getReportList = createAsyncThunk<
  IReportItem[], // 返回的数据类型
  FetchReportListPayload // 传入的参数类型
>('report/fetchReportList', async ({ payload }, { getState }) => {
  const state = getState();
  const user = _.get(state, 'user.user');

  const params = {
    client_id: _.get(user, 'client_id'),
    verify_email: _.get(user, 'email'),
    verify_key: _.get(user, 'verify_key'),
    ...payload,
  };
  const response = fetchReportList(params);

  return response;
});

export const fetchMetric = createAsyncThunk<IReportMetricItem[], void>(
  'report/fetchMetric',
  async () => {
    const metric = await getMetric();
    return metric;
  }
);

export const fetchPeriod = createAsyncThunk<IReportProidItem[], void>(
  'report/fetchPeriod',
  async () => {
    const period = await getPeriod();
    return period;
  }
);

export const fetchCalculationType = createAsyncThunk<IReportCalculationTypeItem[], void>(
  'report/fetchCalculationType',
  async () => {
    const calculationType = await getCalculationType();
    return calculationType;
  }
);

export const fetchChartType = createAsyncThunk<IReportChartTypeItem[], void>(
  'report/fetchChartType',
  async () => {
    const chartType = await getChartType();
    return chartType;
  }
);
