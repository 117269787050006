import { useCallback } from 'react';
// utils
import { AxiosResponse } from 'axios';
import _axios, { _endpoints } from 'src/utils/_axios';
// types
import { IGetReportListParams } from 'src/types/report';
// hooks
import { useAuthContext } from '../auth/hooks';
import { useLocales } from '../locales';

// ----------------------------------------------------------------------

/**
 * 创建 report 数据
 */
export const useCreateReportCallback = () => {
  const auth = useAuthContext();
  const { t } = useLocales();

  return useCallback(
    // todo
    (report: any) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error(t('User is not logged in')));
          return;
        }

        const params = {
          // client_id: auth.user.client_id as string,
          // email: auth.user.email as string,
          // verify_key: auth.user.verify_key as string,
        };

        const data = {
          ...report,
          sensor_list: JSON.stringify(report.sensor_list),
          // todo
          site_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        };

        _axios
          .post(_endpoints.report.create, data, {
            params,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user, t]
  );
};

/**
 * 更新 report 数据
 */
export const useUpdateReportCallback = () => {
  const auth = useAuthContext();
  const { t } = useLocales();

  return useCallback(
    // todo
    (report: any) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error(t('User is not logged in')));
          return;
        }

        const params = {
          client_id: auth.user.client_id as string,
          email: auth.user.email as string,
          verify_key: auth.user.verify_key as string,
        };

        const data = { ...report };

        _axios
          .post(_endpoints.report.update, data, {
            params,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user, t]
  );
};

/**
 * 查詢 report 列表
 */
export const fetchReportList = async (params: Partial<IGetReportListParams>) => {
  const response = await _axios.get(_endpoints.report.list, {
    params,
  });
  return response.data;
};

// 获取表单中的 Metric
export const getMetric = async () => {
  const response = await _axios.get(_endpoints.report.getReportMetric);
  return response.data;
};
// 获取表单中的period
export const getPeriod = async () => {
  const response = await _axios.get(_endpoints.report.getReportPeriod);
  return response.data;
};

// 获取表单中的CalculationType
export const getCalculationType = async () => {
  const response = await _axios.get(_endpoints.report.getReportCalculationType);
  return response.data;
};

// 获取表单中的chartType
export const getChartType = async () => {
  const response = await _axios.get(_endpoints.report.getReportChartType);
  return response.data;
};
